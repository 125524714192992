import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import {
  styled,
  useTheme,
  ThemeProvider,
  createTheme,
} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { CopyToClipboard } from "react-copy-to-clipboard";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import GroupsIcon from "@mui/icons-material/Groups";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import HistoryIcon from "@mui/icons-material/History";
import Snackbar from "@mui/material/Snackbar";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Slider from "@mui/material/Slider";

const DEV_MODE = false;

const EXCLUDED_LOBBIES_COOKIE = "excluded_lobbies";
const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

/**
 * Ideal Counters for upcoming raid bosses and mega raids
 * https://www.futuregamereleases.com/wp-content/uploads/2023/02/pokemon-go-march-2023-content-1.jpg
+-------------------+-----------+---------------+-------+---------------+
|      Counter      | Thundurus | Mega Venusaur | Lugia | Mega Alakazam |
+-------------------+-----------+---------------+-------+---------------+
| Terrakion         | x         |               |       |               |
| Kyogre            | x         |               |       | x             |
| Kyurem            | x         |               |       |               |
| Regigigas         | x         |               |       |               |
| Groudon           |           | x             |       |               |
| Reshiram          |           | x             |       |               |
| Giratina (Origin) |           |               | x     | x             |
| Zekrom            |           |               | x     |               |
| Yveltal           |           |               |       | x             |
| Entei             |           |               |       |               |
| Raikou            |           |               |       |               |
+-------------------+-----------+---------------+-------+---------------+
 */

const names = [
  "Lugia",
  "Alakazam",
  "Venusaur",
  "Thundurus",
  "Gardevoir",
  "Braviary",
  "Kadabra",
  "Sneasel",
  "Grumpig",
  "Litwick"
  // "Ho-Oh",
  // "Rhydon",
  // "Growlithe",
  // "Graveler",
  // "Charmander",
  // "Omastar",
  // "Ponyta",
  // "Torchic",
  // "Numel",
  // "Marowak",
  // "Medicham",
  // "Regidrago",
  // "Charizard",
  // "Tornadus",
  // "Golbat",
  // "Jolteon",
  // "Staraptor",
  // "Cranidos",
  // "Dwebble",
  // "Nosepass",
  // "Sudowoodo",
  // "Swinub",
  // "Exeggutor",
  // "Espurr",
  // "Druddigon",
  // "Crygonal",
  // "Bruxish"
];

function getStyles(name, pokemon, theme) {
  return {
    fontWeight:
      pokemon.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function MyApp() {
  const theme = useTheme();
  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const [pokemon, setPokemon] = React.useState([]);
  const [isSearchActive, setSearchActive] = React.useState(false);
  const [team, setTeam] = React.useState("any");
  const [weather, setWeather] = React.useState(true);
  const [strictMode, setStrictMode] = React.useState(false);
  const [foundLobby, setFoundLobby] = React.useState(false);
  const [lobby, setLobby] = React.useState({});
  const [history, setHistory] = React.useState([]);
  const [autoFriend, setAutoFriend] = React.useState(true);
  const [autoLeave, setAutoLeave] = React.useState(true);
  const [excludeDesertedLobbies, setExcludeDesertedLobbies] =
    React.useState(true);
  const [debugMode, setDebugMode] = React.useState(false);
  const [isNoah, setIsNoah] = React.useState(false);
  const [isDesmond, setIsDesmond] = React.useState(false);
  const [age, setAge] = React.useState([0, 10]);
  const [timeRemaining, setTimeRemaining] = React.useState([0, 45]);
  const [lobbySize, setLobbySize] = React.useState([0, 9]);
  const [abortController, setAbortController] = React.useState(
    new AbortController()
  );

  const handleAgeChange = (event, newValue) => {
    setAge(newValue);
  };

  const handleTimeRemainingChange = (event, newValue) => {
    setTimeRemaining(newValue);
  };

  const handleSizeChange = (event, newValue) => {
    setLobbySize(newValue);
  };

  const handleClick = () => {
    if (lobby?.id) {
      let c = getCookie(EXCLUDED_LOBBIES_COOKIE);
      if (!c) {
        c = `${EXCLUDED_LOBBIES_COOKIE}=`;
      }
      let [cookieName, previouslyExcludedLobbiesString] = c.split("=");
      let previouslyExcludedLobbies =
        previouslyExcludedLobbiesString.split(",");
      console.log(previouslyExcludedLobbies);
      let lobbyId = String(lobby.id);
      if (!previouslyExcludedLobbies.includes(lobbyId)) {
        let excludedLobbies = [lobbyId]
          .concat(previouslyExcludedLobbies)
          .filter((l) => {
            return String(l).trim().length > 0;
          });
        document.cookie = `${EXCLUDED_LOBBIES_COOKIE}=${excludedLobbies.join(
          ","
        )};expires=${new Date(Date.now() + 3_600_000).toUTCString()}';path=/`;
      }
      setShowSnackbar(true);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowSnackbar(false);
  };

  const handleTeamChange = (event) => {
    const {
      target: { value },
    } = event;
    setTeam(value);
  };

  const toggleWeather = (event) => {
    const {
      target: { checked },
    } = event;
    setWeather(checked);
  };

  const toggleAutoFriend = (event) => {
    const {
      target: { checked },
    } = event;
    setAutoFriend(checked);
  };

  const toggleAutoLeave = (event) => {
    const {
      target: { checked },
    } = event;
    setAutoLeave(checked);
  };

  const toggleExcludeDesertedLobbies = (event) => {
    const {
      target: { checked },
    } = event;
    setExcludeDesertedLobbies(checked);
  };

  const toggleDebugMode = (event) => {
    const {
      target: { checked },
    } = event;
    setDebugMode(checked);
  };

  const toggleIsNoah = (event) => {
    const {
      target: { checked },
    } = event;
    setIsNoah(checked);
  };

  const toggleIsDesmond = (event) => {
    const {
      target: { checked },
    } = event;
    setIsDesmond(checked);
  };

  const toggleStrictMode = (event) => {
    const {
      target: { checked },
    } = event;
    setStrictMode(checked);
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPokemon(typeof value === "string" ? value.split(",") : value);
  };

  const findLobbies = (event) => {
    document.cookie = "session=dcdcdc59-a988-4345-8bc6-26de123cff19";
    let cookie = getCookie("session");
    let headers = {};
    let config = {};
    if (!DEV_MODE) {
      headers = {
        ...headers,
        Authorization: `Bearer ${btoa(cookie)}`,
      };
      config = {
        ...config,
        credentials: "include",
      };
    }
    let teamBoost = team === "any" ? "" : team;
    let excludedLobbies = getCookieValue(EXCLUDED_LOBBIES_COOKIE);
    let [minAge, maxAge] = age;
    let [minSize, maxSize] = lobbySize;
    let [minTimeLeft, maxTimeLeft] = timeRemaining;
    let url = DEV_MODE
      ? "https://api-neildev-pokeraider.vaporwar.es"
      : "https://api-prod-pokeraider.vaporwar.es";
    let fullUrl = `${url}/join?strict_mode=${strictMode}&pokemon=${pokemon.join(
      ","
    )}&weather=${weather}&team=${teamBoost}&auto_friend=${autoFriend}&auto_leave=${autoLeave}&exclude_deserted_lobbies=${excludeDesertedLobbies}&excluded_lobbies=${excludedLobbies}&is_noah=${isNoah}&is_desmond=${isDesmond}&debug=${debugMode}`;
    if (minAge || minAge == 0) {
      fullUrl += `&min_age=${minAge}`;
    }
    if (maxAge) {
      fullUrl += `&max_age=${maxAge}`;
    }
    if (minTimeLeft || minTimeLeft == 0) {
      fullUrl += `&min_time_left=${minTimeLeft}`;
    }
    if (maxTimeLeft) {
      fullUrl += `&max_time_left=${maxTimeLeft}`;
    }
    if (minSize || minSize == 0) {
      fullUrl += `&min_players=${minSize}`;
    }
    if (maxSize) {
      fullUrl += `&max_players=${maxSize}`;
    }

    fetch(fullUrl, {
      ...config,
      headers,
      signal: abortController.signal,
    })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          if (result.message == "success") {
            setLobby(result);
            setFoundLobby(true);
            setSearchActive(false);
            addHistory(result);
          } else if (result.retry) {
            findLobbies(event);
          } else {
            setFoundLobby(false);
            setSearchActive(false);
          }
        },
        (err) => {
          console.error("Error", err);
          if (err.name == "AbortError") {
            console.warn("Search aborted.");
            setAbortController(new AbortController());
          }
          setLobby({});
          setFoundLobby(false);
          setSearchActive(false);
        }
      );
    setSearchActive(true);
  };

  function addHistory(result) {
    let past = [
      {
        ...result,
        joined_at: new Date(),
        ordinal: history.length,
      },
    ]
      .concat(history)
      .sort((a, b) => {
        if (a.ordinal < b.ordinal) return 1;
        if (a.ordinal > b.ordinal) return -1;
        return 0;
      });
    console.log(past);
    setHistory(past);
  }

  function getCookie(name) {
    return document.cookie
      .split(";")
      .find((c) => {
        let [key, val] = c.split("=");
        if (key.trim().toLowerCase() == name) {
          return true;
        }
      })
      ?.trim();
  }

  function getCookieValue(name) {
    let c = getCookie(name);
    let [k, v] = c?.split("=") || ["", ""];
    return v.trim();
  }

  const cancelSearch = (event) => {
    abortController.abort();
    setSearchActive(false);
    setAbortController(new AbortController());
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          Friendship code {lobby.code} copied!
        </Alert>
      </Snackbar>
      <Box sx={{ flexGrow: 1 }}>
        <FormGroup>
          <Grid container spacing={2} disableEqualOverflow>
            <Grid xs={12} md={12}>
              {foundLobby ? (
                <Stack sx={{ width: "100%" }} spacing={2}>
                  <Alert
                    variant="filled"
                    action={
                      <div>
                        <CopyToClipboard text={lobby.code}>
                          <Button
                            color="inherit"
                            size="small"
                            onClick={handleClick}
                          >
                            <ContentCopyIcon />
                          </Button>
                        </CopyToClipboard>
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setFoundLobby(false);
                            setLobby({});
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      </div>
                    }
                  >
                    Lobby joined! {lobby.code}
                  </Alert>
                </Stack>
              ) : null}
              <Item>
                <FormControl sx={{ m: 1 }} fullWidth>
                  <InputLabel id="demo-multiple-chip-label">
                    Choose your Pokemon
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    value={pokemon}
                    onChange={handleChange}
                    input={
                      <OutlinedInput
                        id="select-multiple-chip"
                        label="Choose your Pokemon"
                      />
                    }
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {names.map((name) => (
                      <MenuItem
                        key={name}
                        value={name}
                        style={getStyles(name, pokemon, theme)}
                      >
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Item>
            </Grid>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Settings</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid xs={12} md={12}>
                  <Typography>Age</Typography>
                  <Slider
                    getAriaLabel={() => "Age"}
                    value={age}
                    min={0}
                    step={1}
                    max={30}
                    onChange={handleAgeChange}
                    valueLabelDisplay="on"
                  />
                </Grid>
                <Typography>Time Remaining</Typography>
                <Grid xs={12} md={12}>
                  <Slider
                    getAriaLabel={() => "Time Remaining"}
                    value={timeRemaining}
                    min={1}
                    step={1}
                    max={45}
                    onChange={handleTimeRemainingChange}
                    valueLabelDisplay="on"
                  />
                </Grid>
                <Typography>No. Participants / Capacity</Typography>
                <Grid xs={12} md={12}>
                  <Slider
                    getAriaLabel={() => "No. Participants / Capacity"}
                    value={lobbySize}
                    min={1}
                    step={1}
                    max={12}
                    onChange={handleSizeChange}
                    valueLabelDisplay="on"
                  />
                </Grid>
                <Grid xs={12} md={12}>
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Team
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      value={team}
                      onChange={handleTeamChange}
                      name="team"
                    >
                      <Grid container>
                        <Grid xs={3} md={3}>
                          <FormControlLabel
                            value="instinct"
                            control={<Radio />}
                            label="Instinct"
                          />
                        </Grid>
                        <Grid xs={3} md={3}>
                          <FormControlLabel
                            value="valor"
                            control={<Radio />}
                            label="Valor"
                          />
                        </Grid>
                        <Grid xs={3} md={3}>
                          <FormControlLabel
                            value="mystic"
                            control={<Radio />}
                            label="Mystic"
                          />
                        </Grid>
                        <Grid xs={3} md={3}>
                          <FormControlLabel
                            value="any"
                            control={<Radio />}
                            label="Any"
                          />
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid xs={12}>
                  <FormControlLabel
                    control={<Checkbox defaultChecked />}
                    label="Weather Boosted"
                    value={weather}
                    onChange={toggleWeather}
                  />
                </Grid>
                <Grid xs={12}>
                  <FormControlLabel
                    control={<Checkbox defaultChecked />}
                    label="Auto Friend"
                    value={autoFriend}
                    onChange={toggleAutoFriend}
                  />
                </Grid>
                <Grid xs={12}>
                  <FormControlLabel
                    control={<Checkbox defaultChecked />}
                    label="Auto Leave"
                    value={autoLeave}
                    onChange={toggleAutoLeave}
                  />
                </Grid>
                <Grid xs={12}>
                  <FormControlLabel
                    control={<Checkbox defaultChecked />}
                    label="Exclude Deserted Lobbies"
                    value={excludeDesertedLobbies}
                    onChange={toggleExcludeDesertedLobbies}
                  />
                </Grid>
                <Grid xs={12}>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Use Strict Search"
                    value={strictMode}
                    onChange={toggleStrictMode}
                  />
                </Grid>
                <Grid xs={12}>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Debug Mode"
                    value={debugMode}
                    onChange={toggleDebugMode}
                  />
                </Grid>
                <Grid xs={12}>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Are you Noah?"
                    value={isNoah}
                    onChange={toggleIsNoah}
                  />
                </Grid>
                <Grid xs={12}>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Are you Desmond?"
                    value={isDesmond}
                    onChange={toggleIsDesmond}
                  />
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Grid xs={12} md={12}>
              <Item>
                {isSearchActive ? (
                  <Button variant="outlined" onClick={cancelSearch}>
                    Cancel
                    <CircularProgress color="inherit" />
                  </Button>
                ) : (
                  <Button variant="contained" onClick={findLobbies}>
                    Go
                  </Button>
                )}
              </Item>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                <HistoryIcon /> Lobby History
              </Typography>
              <Item>
                <List>
                  {history.map((prior) => {
                    return (
                      <ListItem
                        key={prior.id}
                        secondaryAction={
                          <div>
                            <CopyToClipboard text={prior.code}>
                              <Button
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setLobby(prior);
                                  handleClick();
                                }}
                              >
                                <ContentCopyIcon />
                              </Button>
                            </CopyToClipboard>
                          </div>
                        }
                        disablePadding
                      >
                        <ListItemIcon>
                          <GroupsIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={`${prior.name} Lobby #${prior.id}`}
                          secondary={
                            <React.Fragment>
                              <QrCode2Icon /> {prior.code}
                              <AccessTimeFilledIcon /> {prior.joined_at.toLocaleTimeString('en-US')}
                              via {prior.source}
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </Item>
            </Grid>
          </Grid>
        </FormGroup>
      </Box>
    </ThemeProvider>
  );
}
